.col {
  background-color: #fff;
  opacity: 0;
  position: fixed;
  z-index: 300;
  width: 100%;
  height: 100%;
  bottom: 0;
  top: calc(100% - 10%);
  transition: all .19s ease-in-out;
  overflow-y: auto;
  padding-bottom: 5rem;
  border-top: 1px solid #ccc;
  border-top-right-radius:15px ;
  border-top-left-radius:15px;
}

.entering {
  opacity: 1;
  top: 0;
}

.entered {
  top: 0;
  opacity: 1;
}

.exiting {
  opacity: 0;
}

.exited {
}

.order {
  /* height: 10%; */
  padding: 1rem 0 0 0;
}

.orderTop {
  display: none;
  padding: .2rem 0;
}

.orderTop h3, .orderTop h2{
  margin-bottom: 0;
  font-size: 1.5rem;
}
.order {
  padding: 1rem 0;
}


.sticky {
  position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: auto;
  background: #fff;
  padding: .6rem 0;
  border-bottom: 1px solid #ddd;
  margin-left: auto;
}

.hide {
  margin: 0 .4rem 0 auto;
}


.orderButton {
  text-decoration: none;
  color: #fff;
  border: 1px solid #f4f4f4;
  padding: .7rem 1rem;
  background-color: #4AAB42;
  border-radius: 10px;
  margin: 5% 8% 0 auto;
  font-size: 1.4rem;
  /* margin-right: 10%; */
  width: max-content;
  display: block;
  transition: .2s;
}

.orderButton:hover {
  background-color: var(--color-prim2-dim);
}

.orderSuccess {
  background-color: #4AAB42;
}


/*how to center a div?*/
@media (max-width:1000px) {
  
  .entering {
    top: 10%;
    opacity: 1;
  }

  .entered {
    top: 10%;
    opacity: 1;
  }

  .exiting {
    bottom: 0;
    opacity: 0;
  }

  .orderButton {
    font-size: 1rem;
  }
}

@media  only screen and (orientation: landscape) {
  .entering {
    top: 0;
  }

  .entered {
    top: 0;
  }


}
