.btn {
  padding: 0;
  display: inline-block;
  width: 110px;
  padding: 0.6rem 0;
  margin: 0.5rem;
  border: var(--color-secondary) solid 1.3px;
  border-radius: 12px;
  background-color: transparent;
  font-weight: 400;
  color: var(--color-dark);
  vertical-align: middle;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s;
}

.btn:hover {
  opacity: 0.8;
  color: inherit;
}

.btn.active {
  /* border:transparent; */
  background: #3E3E3F;
  color: #fff;
}

.flexContainer {
  display: flex;
  /* justify-content: space-between; */
  justify-content: space-between;
  align-items: center;
}

.langbtn {
  text-align: center;
  border: none;
  background: #e5e5e5;
  color: #111;
  width: 80px;
  height: 30px;
  border-radius: 4px;
  font-weight: 500;
  margin: 0 0.2rem;
}
.currencybtn {
  width: fit-content;
}

@media (max-width: 1200px) {
  .flexContainer {
    justify-content: space-around;
  }

  .btn {
    width: 90px;
    font-size: 1rem;
    margin-top: 1rem;
  }

  .langbtn {
    width: 60px;
  }
  .currencybtn {
    width: fit-content;
  }
}

@media (max-width: 768px) {
  .btn {
    margin-bottom: 0;
  }
}
