.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  z-index: 300;
  background-color: #504e4eb2;
  backdrop-filter: blur(7.5px);
}

.hide {
  position: absolute;
  top: 2%;
  left: 5%;
  color: #fff;
  cursor: pointer;
  opacity: 1;
  font-size: 2.5rem;
}