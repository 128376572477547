.MainGrid {
  margin: 1rem 0 4rem 0;
  min-height: 60vh;
}

.noItems {
  padding-left: 5%;
  font-size: 1.7rem;
}

@media (max-width:768px) {
  .noItems {
    font-size: 1rem;
  }
}