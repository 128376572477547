.flex {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  cursor: pointer;
  /* padding: 1rem 0; */
}

.qty {
  color: var(--color-order);
  font-weight: 800;
  font-size: 1.5rem;
}
.qty span {
  font-weight: 500;
}

.total {
  color: var(--color-order);
  font-weight: 800;
  font-size: 1.5rem;
}

.total span {
  font-weight: 500;
}

.rightToLeft.flex {
  flex-direction: row-reverse;
}

.rightToLeft h2,
.rightToLeft h3 {
  display: flex;
  flex-direction: row-reverse;
}

@media (max-width: 1000px) {
  .flex {
    justify-content: space-evenly;
    /* height: 7%; */
  }
  .total {
    font-size: 1.5rem;
  }
  .qty {
    font-size: 1.5rem;
  }
}

@media (max-height: 580px) {
  .flex {
    align-items: center;
  }
}

@media (max-width: 600px) {
  .total {
    font-size: 1.2rem;
  }
  .qty {
    font-size: 1.2rem;
  }
}
