.main {
  position: relative;
  cursor: pointer;
  margin: 0.9rem 0.1rem 0.2rem 0.5rem;
  border-radius: 8px;

  background: #EE1D23;
}

.box_shadow {
  /* box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset; */

    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;

    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.active {
  box-shadow: 0px 0px 10px var(--color-genre) !important;
}

.image {
  /* width: 15vw; */
  height: 15vw;
}

.img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 15px;
}

.overlay {
  position: absolute;
  top: 0;
  /* background-color: rgba(0, 0, 0, 1); */
  background-color: rgba(0, 0, 0, 0.349);
  width: 100%;
  height: 100%;
  z-index: 100;
  border-radius: inherit;
}

.label {
  color: #fff;
  position: absolute;
  top: 50%;
  text-align: center;
  font-size: 1.7rem;
  left: 50%;
  z-index: 101;
  transform: translate(-50%, -50%);
}

@container (min-width:1024px) {
  .image {
    width: 19vw;
    height: 19vw;
  }
}

@media (max-width: 600px) {
  .image {
    height: 24vw;
    width: 23vw;
  }
}

@media only screen and (orientation: landscape) {
  @media (max-width: 1024px) {
    .image {
      height: 15.5vw;
      width: 15.5vw;
    }
  }
  @media (max-width: 600px) {
    .image {
      height: 24vw;
      width: 24vw;
    }
  }
  @media (max-width: 480px) {
    .image {
      height: 23.5vw;
      width: 23.5vw;
    }
  }
}
@media (max-width: 768px) {
  .label {
    font-size: 0.9rem;
  }
}
