.main {
  z-index: 400;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 90vh;
  overflow-y: auto;
  padding: 0;
  border-radius: 15px;
  scrollbar-width: 2px;
}

.menu_price {
  
}

.flex2 {
  width: 100%;
  padding: 0 1rem 1rem 1rem;
}

.image {
  height: 50vh !important;
  width: 100%;
  /* height: 300px; */
  padding: 0 .5rem .5rem .5rem;
  /* border-radius: 8px !important; */
}

.image img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.title {
  margin-bottom: 0;
}

.desc {
  color: #888888;
  font-size: 1.2rem;
}

.list {
  list-style-type: disc;
  color: #888888;
  font-size: 1.1rem;
}

.flex {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.price {
  font-weight: bolder;
  color: var(--color-dark);
  font-size: 2rem;
}



.imageFocus {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 100%;
  max-height: 80%;
  z-index: 400;
  object-fit: contain;
}

.sticky {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 .8rem;
  width: 100%;
  margin-bottom: .4rem;
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
}



.hideImageFocus {
  position: fixed;
  top: 5%;
  right: 5%;
  z-index: 4000;
  color: #fff;
  cursor: pointer;
  opacity: 1;
  font-size: 1.6rem;
}

.rightToLeft .desc, .rightToLeft .list{
  text-align: right;
  direction: rtl;
}

 .rightToLeft .flex {
  flex-direction: row-reverse;
}

@media (max-width:768px) {
    .main {
      width: 90%;
      overflow-y: auto;
      max-height: 80vh;
    }

    .image {
      width: 100%;
      height: 300px;
    }
    .image img {
      border-radius: 10px;

    }

    .desc {
      color: #888888;
      font-size: 1rem;
    }
}



@media (max-height:600px) {
  @media (max-width:400px) {
    .main {
      max-height: 90%;
      width: 90%;
    }
    
    .image {
      /* height: max-content; */
      width: 100%;
      height: 80%;
      object-fit: cover;
    }
    .image img {
      border-radius: 10px;
      height: 100%;
    }
  }
}