.main2 {
  background: #f9f9f9 url('../../images/bg-main.png') repeat;
  background-repeat: repeat;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.main {
  background: #f9f9f9 url('../../images/bg-main.png') repeat;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  margin: 0 auto;
  background-color: transparent;
  height: 100%;
  position: relative;
  width: 100%;
  top: -60px;
}


@media (max-width:768px) {
    .main {
      top: -23px;
    }
    
    .main2 {
      position: sticky;
      top: -1.7cm;
      /* top: -8vh; */
      z-index: 2;
    }
  }
  
    @media  only screen and (orientation: landscape) {
      .main {
        top: -40px;
      }

      .main2 {
        position: static;
      }
    }