.imageContainer {
  position: fixed;
  bottom: 20px;
  right: 15px;
  width: 50px;
  height: 50px;
  z-index: 5000;
}

.imageContainer img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

@media (max-width:768px) {
  .imageContainer {
    width: 40px;
    height: 40px;
    bottom: 10px;
    right: 4px;
  }
}