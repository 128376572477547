.flex {
  display: flex;
  border: 1px solid #f2f2f2;
  background-color: #fff;
  justify-content: space-between;
  box-shadow: 5px 5px 0 0 #E7E7E7;
  margin: .5rem auto;
  border-radius: 20px;
  width: 100%;
  padding: .5rem;
  position: relative;
  cursor: pointer;
  margin-bottom: 1rem;
}


hr.hrCategory {
  overflow: visible; /* For IE */
  height: 30px;
  border-style: solid;
  border-color: #000;
  border-width: 2px 0 0 0;
  border-radius: 20px;
  margin-top: 1rem;
  margin-bottom: 0;
  opacity: .6;
}
hr.hrCategory:before { /* Not really supposed to work, but does */
  display: block;
  content: "";
  height:30px;
  margin-top: -31px;
  border-style: solid;
  border-color: black;
  border-width: 0 0 2px 0;
  border-radius: 20px;
}

hr.end {
  border: none;
  opacity: .6;
border-top: 6px double #333;
color: #333;
overflow: visible;
text-align: center;
height: 5px;
margin: 1rem 0;
}

hr.end:after {
background: #fff;
content: '§';
font-weight: bolder;
padding: 0 4px;
position: relative;
top: -13px;
}



.image {
  width: 100%;
  max-width: 30%;
  background-color: aqua;
}


.flexInner img {
  width: 300px;
  vertical-align: middle;
  height: 100%;
  max-height: 200px;
  margin-right: 1rem;
  object-fit: cover;
  padding: .2rem;
  border-radius: 15px;
}

.flexInner {
  display: flex;
  align-items: center;
}

.rightSide {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  height: 100%;
  justify-content: space-between;
  align-items: flex-start;
}

.mb {
  position: absolute;
  bottom: 0%;
  right: 2%;
}

.mb2 {
  position: absolute;
  bottom: 2%;
  left: 3%;
}

hr {
  border: none;
  margin: .3rem 0;
}



/* Category */
.flexCat {
  display: flex;
  align-items: center;
  margin: 0 1rem;
}

.category {
  font-weight: 700;
  font-size: 2rem;
  color: #000;
}

.smImage {
  width: 8%;
  height: 100px;
  margin-left: auto;
  overflow: hidden;
}

.smImage img {
  border-radius: 8px;  
  object-fit: cover;
  height: 100%;
  width: 100%;
  position: relative;
}


.addrem {
  position: absolute;
  bottom: 5%;
  right: 3%;
}

.title {
  display: block;
  font-size: 2rem;
  font-weight: bold;
}

p {
  margin-bottom: .5rem;
}


.description {
  font-size: 1.5rem;
}

.price {
  display: block;
  font-size: 1.8rem;
  font-weight: bolder;
  justify-self: flex-end;
  color: var(--color-dark);
  margin-right: .8rem;
}



/* LANGUAGE CHANGE DIRECTION */
.rightToLeft.flex {
  /* box-shadow: -1px -1px 4px 5px #E7E7E7; */
}

.rightToLeft .smImage {
  margin-left: 0;
  margin-right: auto;
}

.rightToLeft .flexInner {
  flex-direction: row-reverse;
  width: 100%;
}

.rightToLeft .addrem2 {
  position: absolute;
  left: 5%;
  bottom: 3%;
}
.rightToLeft .rightSide {
  text-align: right;
}

.rightToLeft.flexCat {
  flex-direction: row-reverse;
}
.rightToLeft .price {
  align-self: flex-end;
}

@media (max-width:1100px) {
  .flex {
    width: 90%;
    margin: .6 auto;
  }
  
  
  hr.hrCategory {
    opacity: .7;
    border-width: 2px 0 0 0;
  }
  hr.hrCategory:before { /* Not really supposed to work, but does */
    border-width: 0 0 2px 0;
    
  }

  hr.end {
    border-top: 5px double #333;
    margin-bottom: 1.5rem;
  }

  
  .smImage {
    height:60px;
    width: 60px;
  }
  
  .smImage img {
    height: 100%;
    width: 100%;
    object-fit: cover; /* scale the image to cover the entire container */
  }

  hr.end:after {
    font-weight: 500;
}

}

@media (max-width:900px) {
  
  .category {
    font-weight: 700;
    font-size: 1.8rem;
    padding-left: 1%;
    display: inline-block;
  }
  
  .description {
    font-size: 1rem;
  }
  .flex {
    padding: .3rem;
  }

  .title,.price {
    font-size: 1.1rem;
  }
  .flexInner img {
    width: 120px;
    height: 100%;
    margin: 0 7px 0 0;
  }

  .smImage {
    height: 40px;
    width: 40px;
  }
  
  .smImage img {
  }

  .rightSide {
    padding: .2rem;
  }

  .DummyImage {
    margin: 0 !important;
  }
}

@media (max-width:768px) {

  .flex {
    box-shadow: 2px 5px 2px .2px #E7E7E7;
    padding: .3rem;
    width: 97%;
    margin: .6rem auto;
    padding: .5rem;
  }
  .smImage {
    height: 35px;
    width: 35px;
  }
  
  .smImage img {
  }
  .category {
    font-weight: 700;
    font-size: 1.5rem;
    margin-top: 0;
  }
  
  .description {
    font-size: .9rem;
  }

  .title,.price {
    font-size: 1.1rem;
  }
  .flexInner img {
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 10px;
  }

}

@media (max-width:359px) {
  .mb {
    bottom: -5%;
    z-index: 1;
  }
  .mb2 {
    bottom: -5%;
    z-index: 1;
  }
}