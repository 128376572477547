.fixed {
  background-color: #FFFFFF;
  position: fixed;
  z-index: 200;
  height: 10%;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #ccc;
}

.qty {
  color: #A03F00;
  font-weight: 800;
}
.qty span {
  font-weight: 500;
  
}

.total {
  color: #A03F00;
  font-weight: 800;
}

.total span {
  font-weight: 500;
}


@media (max-width: 1000px) {
  .fixed {
    height: auto;
    padding: .8rem 0;
  }

  .total {
  }
  
  .total span{
    font-weight: 900;
    font-size: 3rem;
  }
  .qty {
    font-size: .5rem;
  }
}