.flex {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-auto-rows: 1fr 1fr;
  margin: .5rem 2rem;
  align-items: center;
  justify-content: center;
  justify-items: stretch;
}

.flexcol {
  align-self: center;
  margin-left: 1rem;
}

.total {
  color: var(--color-dark);
  font-weight: 800;
}

.total span {
  font-weight: bold;
}
.section1 {
  display: flex;
}

.image {
  width: 23%;
  vertical-align: middle;
  align-self: center;
}

.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}

.section2 {

}

.section3 {
  color: var(--color-dark);
  font-weight: bold;
  align-self: self-end;
  justify-self: self-end;
  margin-right: 10%;
}

.section3 span {
  font-weight: 800;
}


.rightToLeft {
  grid-template-columns: 1fr 3fr;
}

.rightToLeft .section1 {
  order: 1;
  flex-direction: row-reverse;
  text-align: right;
}

.rightToLeft .flexcol {
  margin-right: 1rem;
}

.rightToLeft .section3  {
  align-self: flex-end;
}


@media (max-width:768px) {
  .flex {
    margin: 1.2rem .7rem;

  }
  
  .image {
    width: auto;
    height: 100%;
  }

  .image img {
    object-fit: contain;
    width: 110px;
    height: 100%;
  }
  
  .flexcol h1 {
    font-size: 1.1rem;
    font-weight: bold; 
  }

  .section3 {
    margin: 0;
  }
  .rightToLeft .section3  {
    align-self: flex-end;
    margin: auto;
  }
}