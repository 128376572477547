.carouselMain {
  height: 85vh;
}

.carouselMain img {
  height: 85vh !important;
}


.mainLogo {
  width: 100vw;
    object-fit: contain;
    align-items: center;
    text-align: center;
}
@media (max-width:1100px) {
  .carouselMain {
    height: 75vh;
  }

  .carouselMain img {
    height: 75vh !important;
  }

}


@media (max-width:500px) {
  .carouselMain {
    height: 40vh;
  }

  .carouselMain img {
    height: 40vh !important;
  }

}
