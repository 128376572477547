.main {
  height: fit-content;
  width: 100%;
  background-color: #A03F00;
  display: grid;
  justify-items: center;
  align-items: center;
  overflow-x: scroll;
  overflow-y: hidden;
  grid-auto-flow:column;
  grid-auto-columns: max-content;
  cursor: pointer;
}

.main span {
  margin: .8rem 1rem;
  border-radius: 10px;
  padding: .7rem;
  border: 1px solid #fff;
  color: #fff;
}

.main::-webkit-scrollbar {
  display: none;
}

.main span.active {
  color: #A03F00;
  background-color: #fff;
  
}



@media (max-width:768px) {
  .main span {
    margin: .8rem .6rem;
    font-size: .8rem;
    color: #fff;
  }
  
}