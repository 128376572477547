.pm {
  color: var(--color-prim);
  font-size: 1.7rem;
  display: inline-block;
  
}

.pm:hover {
  cursor: pointer;
}

.counter {
  margin: 0 .8rem;
  border: none;
  width: 29px;
  text-align: center;
  outline: none;
  font-weight: 500;
  font-size: 1.4rem;
  width: 5.1vw;
}

@media (max-width:768px) {
    .pm {
      font-size: 1.4rem;
    }

    .counter {
      margin: 0;
      font-size: 1rem;
      width: 35px;
    }
}