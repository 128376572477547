.gridContainer {
  display: flex;
  gap: 0.5rem;
  overflow-y: hidden;
  overflow-x: auto;
  grid-auto-flow: column;
  height: 100%;
  width: auto;
  max-width: 95%;
  /* grid-auto-columns: 10%; */
}

.borderBottom {
  border-bottom: 1px solid #ccc;
  padding-top: 10px;
}

@media (max-width: 1000px) {
  .gridContainer {
    grid-auto-columns: 23%;
    font-size: 1rem;
    width: 100%;
    gap: 0;
  }
}

@media (max-width: 768px) {
  .gridContainer {
    max-width: 97%;
    margin: 0 auto;
  }

  .gridContainer::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (orientation: landscape) and (max-width: 1000px) {
  .gridContainer {
    grid-auto-columns: 15%;
  }

  .gridContainer::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: 380px) {
  .gridContainer {
    max-height: 140px;
  }
}
