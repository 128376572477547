@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

:root {
  --color-prim: #000000;
  --color-prim-dim: #000000c0;
  --color-prim2: #00ccff;
  --color-prim2-dim: #00ccffa0;
  --color-secondary: #000000;
  --color-order: #222;
  --color-dark: #222;
  --color-genre: #7e3b1c;
  --color-pills: #ed5922;
}
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", "sans-serif";
}

.object_image {
  object-fit: cover;
}

.imagestyling {
  width: 100%;
  height: 70vh;
  object-fit: cover;
}
.whole_image {
  height: 85vh !important;
  width: 100%;
}

.spinner {
  width: 40px;
  height: 40px;
  margin: 100px auto;
  background-color: #333;

  border-radius: 100%;
  -webkit-animation: sk-scaleout 1s infinite ease-in-out;
  animation: sk-scaleout 1s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
.my-submit-button {
  color: black !important;
}

.my-cancel-button {
  color: black !important;
}

.text-success {
  color: #4aab42 !important;
}

.fixLink {
  text-decoration: underline;
  color: #fff;
  padding-right: 0.5rem;
}

.loadingFallback {
  height: 65vh;
  /* background-color: aqua; */
  width: 100%;
}

.error {
  display: flex;
  font-weight: 600;
  align-items: center;

  justify-content: center;
  padding: 2rem 0;
  color: rgb(187, 10, 10);
}

.error span {
  font-weight: 500;
  color: black;
  font-size: 1.5rem;
}

.loadingFallback img {
  position: relative;
  top: 0;
  left: 50%;
  transform: translatex(-50%);
  height: 100px;
  z-index: 500;
  width: 30%;
  height: auto;
  object-fit: cover;
}

i.fa-x {
  font-size: 0.9rem !important;
  font-weight: 100 !important;
  cursor: pointer;
  /* color: rgb(179, 176, 176); */
}

.fallback {
  display: block;
  background: #666;
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.hide {
  text-align: right;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 50%;
  background-color: #ccc;
  color: #fff;
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
  /* margin-top: .7rem; */
  padding: 0.7rem;
  /* align-self: normal; */
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.loading img {
  object-fit: contain;
  width: 70%;
}

.loading img {
  display: block;
  margin: 0 auto;
}

.contain {
  max-width: 95vw;
  margin: 1rem auto;
}

.relative {
  position: relative;
}

.linebreak {
  width: 80%;
  background-color: #ccc;
  display: block;
  height: 2px;
  margin: auto;
}

/* SLICK SLIDE--------------------------------- */

.slickContainer {
  /* display: grid; */
  width: 100%;
  margin: 0 auto;
}

.slick-slide > div {
  display: grid;
  /* place-items: center; */
  width: 100%;
  margin-top: 50px;
  margin: 0;
  padding: 0px;
}

/* end-------------------------------------end */

/* start */
.powered_by {
  position: fixed;
  bottom: 0;
  left: 1%;
  color: #fffb00;
  z-index: 10000;
  margin: 0;
  padding: 0.5rem 1rem 0 1rem;
  border-radius: 5px;
  background-color: #fff;
  font-size: 0.9rem;
  font-weight: 700;
}
.powered_by span {
  color: #000;
  font-size: 0.7rem;
  font-weight: 500;
}
/* end */

@media screen and (orientation: landscape) and (max-width: 768px) {
  .contain {
    max-width: 100%;
  }
}
@media (max-width: 768px) {
  .imagestyling {
    /* width: auto; */
    height: 40vh;
    object-fit: scale-down;
  }
  .contain {
    max-width: 100%;
    margin: 0 0;
  }
  body {
    line-height: 1.2;
  }
}
